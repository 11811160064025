<template>
  <b-card title="Proses Gaji">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Nama Komponen" label-for="component_name">
                  <validation-provider #default="{ errors }" name="component_name" rules="required">
                    <b-form-input id="component_name" v-model="dataParent.component_name" type="text"
                      :state="errors.length > 0 ? false : null" placeholder="Nama Komponen" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Periode" label-for="periode_date">
                  <validation-provider #default="{ errors }" name="periode_date" rules="required">
                    <b-form-input id="periode_date" v-model="dataParent.periode_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Periode" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                    @click="formSubmitted">
                    Proses
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" :rtl="direction"
          :search-options="{ enabled: true, externalQuery: searchTerm, }"
          :pagination-options="{ enabled: true, perPage: pageLength, }" theme="black-rhino">
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select v-model="pageLength" :options="['10', '50', '100']" class="mx-1" @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                " />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import Table from '@/views/table/vue-good-table/GoodTable.vue'
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { codeAdvance } from './code'

export default {
  components: {
    // BCardCode,
    BCard,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataParent: {
        component_name: '',
        periode_date: '',
      },
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'NIP',
          field: 'nip',
        },
        {
          label: 'Nama Lengkap',
          field: 'name',
        },
        {
          label: 'L/P',
          field: 'gender',
        },
        {
          label: 'Aktif',
          field: 'is_active',
        },
        {
          label: 'Jabatan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Masa Kerja',
          field: 'working_period',
        },
        {
          label: 'Usia',
          field: 'age',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {

  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/payrollprocesses', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Payroll Proses Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'transaksi-proses-gaji' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Payroll Proses Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    toRouteEditPegawai(val) {
      this.$router.push({ name: 'data-pegawai-edit', params: { id: val } })
    },
    toRouteDetailPegawai(val) {
      this.$router.push({ name: 'data-pegawai-detail', params: { id: val } })
    },
    async getAllEmployee() {
      try {
        const response = await this.$http.get('/employees')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Employee Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/employees/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Employee Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Employee Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
